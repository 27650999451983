import APIService from '@/service/APIService';
import http from '@/http/token-http';

class MemberService extends APIService {
  constructor() {
    super('/members');
  }

  indexPaginated(page = 1, onlyActive = true) {
    return this.index({ params: { page, onlyActive } });
  }

  // eslint-disable-next-line class-methods-use-this
  searchNonMembers(searchTerm = '') {
    const term = searchTerm.toLowerCase();
    return http.get('/non-members', { params: { like: `email,${term}`, page: 1 } })
      .then((res) => res.data.data);
  }
}

export default new MemberService();
