var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('md-dialog',{attrs:{"md-close-on-esc":false,"md-click-outside-to-close":false,"md-active":_vm.isModalVisible},on:{"update:mdActive":function($event){_vm.isModalVisible=$event},"update:md-active":function($event){_vm.isModalVisible=$event}}},[_c('md-dialog-content',[_c('validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('span',{staticClass:"md-headline"},[_vm._v(_vm._s(_vm.title))]),(!_vm.object.id)?_c('rule',{attrs:{"name":"membre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-autocomplete',{attrs:{"md-options":_vm.users},on:{"md-changed":_vm.getUsers,"md-opened":_vm.getUsers,"md-selected":_vm.addMember},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" pas de résultat pour: "+_vm._s(term)+" ")]}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('label',[_vm._v("Utilisateur")]),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)}):_vm._e(),_c('rule',{attrs:{"name":"abonnement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Abonnement")]),_c('md-select',{attrs:{"name":"membership"},model:{value:(_vm.object.membership_id),callback:function ($$v) {_vm.$set(_vm.object, "membership_id", $$v)},expression:"object.membership_id"}},_vm._l((_vm.memberships),function(membership){return _c('md-option',{key:membership.id,attrs:{"value":membership.id}},[_vm._v(" "+_vm._s(membership.name)+" ")])}),1),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('rule',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',{staticClass:"input-date-label"}),_c('datetime',{attrs:{"value-zone":"Europe/Zurich","format":_vm.dateFormat,"type":"datetime","input-class":"input-datetime","auto-continue":"","auto-close":"","placeholder":"Sélectionner une date","phrases":{ok:'ok', cancel:'annuler'}},model:{value:(_vm.object.end_membership),callback:function ($$v) {_vm.$set(_vm.object, "end_membership", $$v)},expression:"object.end_membership"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"buttons-panel"},[_c('md-button',{staticClass:"md-raised md-accent",on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"submit"}},[_vm._v("Valider")])],1)],1)]}}])})],1)],1),_c('div',{staticClass:"fab-space"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }