<template>
  <div>
    <md-list-item class="md-layout">

      <div class="md-layout md-alignment-center md-xsmall-size-85">
        <div class="md-layout-item md-xsmall-size-100 md-list-item-text">
          <span>{{ object.name }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-xsmall-hide md-list-item-text-hide ">
          <span>{{ object.email }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-xsmall-hide md-list-item-text">
          <span>{{ object.membership.name }}</span>
        </div>
        <div class="md-layout-item md-xsmall-size-100">
          <span>{{ object.end_membership | dateFR }}</span>
        </div>
      </div>

      <div class="md-layout md-layout-item md-size-10 md-small-20 md-xsmall-size-15">
        <div class="btn-edit">
          <md-button @click="onEdit" class="md-icon-button md-dense md-raised md-accent btn-green">
            <md-icon>mode</md-icon>
          </md-button>
        </div>
      </div>

    </md-list-item>
    <md-divider class="md-full"></md-divider>
  </div>
</template>

<script>

import translationMixin from '@/mixins/translation-mixin';
import itemMixin from '@/mixins/item-mixin';
import UserService from '@/components/user/UserService';

export default {
  name: 'MemberItem',
  data: () => ({
    service: UserService,
  }),
  mixins: [itemMixin, translationMixin],
};

</script>
