<template>
  <div>
    <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false"
               :md-active.sync="isModalVisible">

      <md-dialog-content>
        <validator v-slot="{ handleSubmit }">
          <form novalidate @submit.prevent="handleSubmit(onSubmit)">

            <span class="md-headline">{{ title }}</span>

            <rule name="membre" rules="required" v-slot="{errors}" v-if="!object.id">
              <md-autocomplete v-model="value" :md-options="users" @md-changed="getUsers"
                               @md-opened="getUsers" @md-selected="addMember">
                <label>Utilisateur</label>
                <template slot="md-autocomplete-item" slot-scope="{ item }">
                  {{ item.email }}
                </template>
                <template slot="md-autocomplete-empty" slot-scope="{term}"> pas de résultat
                  pour: {{ term }}
                </template>
                <span class="input-error">{{ errors[0] }}</span>
              </md-autocomplete>
            </rule>

            <rule name="abonnement" rules="required" v-slot="{errors}">
              <md-field>
                <label>Abonnement</label>
                <md-select v-model="object.membership_id" name="membership">
                  <md-option v-for="membership of memberships" :key="membership.id"
                             :value="membership.id">
                    {{ membership.name }}
                  </md-option>
                </md-select>
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <rule name="date" rules="required" v-slot="{errors}">
              <md-field>
                <label class="input-date-label"></label>
                <datetime
                  value-zone="Europe/Zurich"
                  :format="dateFormat"
                  type="datetime"
                  input-class="input-datetime"
                  v-model="object.end_membership"
                  auto-continue
                  auto-close
                  placeholder="Sélectionner une date"
                  :phrases="{ok:'ok', cancel:'annuler'}"
                />
                <span class="input-error">{{ errors[0] }}</span>
              </md-field>
            </rule>

            <div class="buttons-panel">
              <md-button class="md-raised md-accent" @click="close">Annuler</md-button>
              <md-button type="submit" class="md-raised md-primary">Valider</md-button>
            </div>

          </form>
        </validator>
      </md-dialog-content>
    </md-dialog>
    <div class="fab-space"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Datetime } from 'vue-datetime';
import { sqlDateTimeFormat } from '@/date-format';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import formMixin from '@/mixins/form-mixin';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import MemberService from '@/components/member/MemberService';
import { DateTime } from 'luxon';

extend('required', {
  ...required,
  message: messages.required,
});
export default {
  name: 'MemberForm',
  data: () => ({
    model: 'Membre',
    service: MemberService,
    dateFormat: sqlDateTimeFormat,
    value: null,
    users: [],
    newMember: {},
  }),
  components: {
    datetime: Datetime,
    validator: ValidationObserver,
    rule: ValidationProvider,
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters(['memberships', 'getFirstMembershipId']),
  },
  methods: {
    getDefaultObject() {
      return {
        membership_id: this.getFirstMembershipId,
        end_membership: DateTime.now().plus({ days: 30 }).toISO(),
      };
    },
    getUsers(searchTerm) {
      if (typeof searchTerm === 'string' || searchTerm === undefined) {
        this.users = this.service.searchNonMembers(searchTerm);
      }
    },
    addMember(user) {
      this.newMember = { ...user };
      this.value = user.email;
    },
    async onSubmit() {
      try {
        this.newMember = { ...this.newMember, ...this.object };
        const object = await this.service.update(this.newMember.id, this.newMember);

        this.showSuccess(this.submitMessage);
        this.$emit('submit', object);
        this.close();
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
</script>
